.project__page__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin: 2rem;
    overflow-y: auto;
    background-color: var(--color-bg);
}

